<template>
	<div class="box">
		<div class="null-page" v-show="yes"></div>
		
		<el-card class="box-card order-detail">
			<div slot="header" class="clearfix">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/member/order_list' }">รายการคําสั่งซื้อ</el-breadcrumb-item>
					<el-breadcrumb-item>รายละเอียดการสั่งซื้อ</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div v-loading="loading">
				<template v-if="orderDetail">
					<div class="order-status">
						<h4 style="position: relative;">
							สถานะคําสั่งซื้อ：
							<span class="ns-text-color">{{ orderDetail.order_status_name }}</span>
							<div class="edit-time" v-if="orderDetail.order_status == 0">
								<img src="../../assets/images/order_time.png" style="width: 15px;height: 15px;margin-right: 6px;" />คําสั่งระยะทางจะถูกปิดโดยอัตโนมัติ，เศษ
								<count-down
									style="color: #f00;margin-left: 10px;"
									class="count-down"
									v-on:start_callback="countDownS_cb()"
									v-on:end_callback="countDownE_cb()"
									:currentTime="orderDetail.currentTime"
									:startTime="orderDetail.startTime"
									:endTime="orderDetail.endTime"
									:dayTxt="':'"
									:hourTxt="':'"
									:minutesTxt="':'"
									:secondsTxt="''"
								></count-down>
							</div>
						</h4>
						<div v-if="orderDetail.order_status == 0" class="go-pay">
							<p>
								ต้องชําระเงิน：
								<span>฿{{ orderDetail.pay_money }}</span>
							</p>
						</div>

						<div class="operation" v-if="orderDetail.action.length > 0">
							<el-button type="primary" size="mini" plain v-if="orderDetail.is_evaluate == 1" @click="operation('memberOrderEvaluation')">
								<template v-if="orderDetail.evaluate_status == 0">
									โพสต์รีวิว
								</template>
								<template v-else-if="orderDetail.evaluate_status == 1">
									โพสต์รีวิว
								</template>
							</el-button>

							<el-button
								type="primary"
								size="mini"
								:plain="operationItem.action == 'orderPay' ? false : true"
								v-for="(operationItem, operationIndex) in orderDetail.action"
								:key="operationIndex"
								@click="operation(operationItem.action)"
							>
								{{ operationItem.title }}
							</el-button>
						</div>
						<div class="operation" v-else-if="orderDetail.action.length == 0 && orderDetail.is_evaluate == 1">
							<el-button type="primary" size="mini" plain v-if="orderDetail.is_evaluate == 1" @click="operation('memberOrderEvaluation')">
								<template v-if="orderDetail.evaluate_status == 0">
									โพสต์รีวิว
								</template>
								<template v-else-if="orderDetail.evaluate_status == 1">
									โพสต์รีวิว
								</template>
							</el-button>
						</div>
					</div>

					<div class="verify-code-wrap" v-if="orderDetail.virtual_goods">
						<template v-if="orderDetail.goods_class == 2">
							<h4>รหัสยืนยัน</h4>
							<div class="virtual-code">
								<img :src="$img(orderDetail.virtualgoods)" />
								<div class="tips">โปรดแสดงคิวอาร์โค้ดต่อเจ้าหน้าที่ตรวจสอบ</div>
								<div>รหัสยืนยัน：{{ orderDetail.virtual_code }}</div>
							</div>
							<h4>ข้อมูลการตัดจําหน่าย</h4>
							<ul>
								<li>จํานวนการตัดจําหน่าย：เศษ{{ orderDetail.virtual_goods.total_verify_num - orderDetail.virtual_goods.verify_num }}ครั้ง/สามัญ{{ orderDetail.virtual_goods.total_verify_num }}ครั้ง</li>
								<li>วันหมดอายุ：
									<span v-if="orderDetail.virtual_goods.expire_time > 0">	{{ $util.timeStampTurnTime(orderDetail.virtual_goods.expire_time) }}</span>
									<span v-else>ใช้ได้อย่างถาวร</span>
								</li>
							</ul>
							<template v-if="orderDetail.virtual_goods.verify_record.length">
								<h4>บันทึกการตัดจําหน่าย</h4>
								<ul v-for="(item, index) in orderDetail.virtual_goods.verify_record" :key="index">
									<li>คนตัดจําหน่าย：{{ item.verifier_name }}</li>
									<li>เวลาตัดจําหน่าย：{{ $util.timeStampTurnTime(item.verify_time) }}</li>
								</ul>
							</template>
						</template>
						<template v-if="orderDetail.goods_class == 3">
							<h4>ข้อมูลลับของการ์ด</h4>
							<ul v-for="(item, index) in orderDetail.virtual_goods" :key="index">
								<li>
									<span>หมายเลขบัตร：{{ item.card_info.cardno }}</span>
								</li>
								<li>
									<span>รหัสผ่าน：{{ item.card_info.password }}</span>
								</li>
							</ul>
						</template>
						<!-- <h4>ข้อมูลการตัดจําหน่าย</h4>
						<ul>
							<li>
								รหัสยืนยัน：
								<span class="ns-text-color">{{ orderDetail.virtual_code }}</span>
							</li>
							<template v-if="orderDetail.virtual_goods.is_veirfy">
								<li>สถานะการตัดจําหน่าย：ตัดออก</li>
								<li>เวลาตัดจําหน่าย：{{ $util.timeStampTurnTime(orderDetail.virtual_goods.verify_time) }}</li>
							</template>
						</ul>
						<img :src="$img(orderDetail.virtualgoods)" /> -->
					</div>

					<div class="order-info">
						<h4>ข้อมูลการสั่งซื้อ</h4>
						<ul>
							<!-- <li>
								<i class="iconfont iconmendian"></i>
								ร้าน：
								<router-link :to="'/shop-' + orderDetail.site_id" target="_blank">{{ orderDetail.site_name }}</router-link>
							</li> -->
							<li>ประเภทคําสั่งซื้อ：{{ orderDetail.order_type_name }}</li>
							<li>หมายเลขคําสั่งซื้อ：{{ orderDetail.order_no }}</li>
							<li>หมายเลขธุรกรรมคําสั่งซื้อ：{{ orderDetail.out_trade_no }}</li>
							<li>เวลาในการสร้าง：{{ $util.timeStampTurnTime(orderDetail.create_time) }}</li>
							<li v-if="orderDetail.close_time > 0">เวลาปิดเครื่อง：{{ $util.timeStampTurnTime(orderDetail.close_time) }}</li>
							<template v-if="orderDetail.pay_status > 0">
								<li>วิธีการชําระเงิน：{{ orderDetail.pay_type_name }}</li>
								<li>เวลาชําระเงิน：{{ $util.timeStampTurnTime(orderDetail.pay_time) }}</li>
							</template>
							<li v-if="orderDetail.promotion_type_name != ''">กิจกรรมร้านค้า：{{ orderDetail.promotion_type_name }}</li>
							<li v-if="orderDetail.buyer_message != ''">ข้อความจากผู้ซื้อ：{{ orderDetail.buyer_message }}</li>
						</ul>
					</div>
					<!-- ข้อมูลใบแจ้งหนี้ -->
					<div class="take-delivery-info" v-if="orderDetail.is_invoice ==1">
						<h4>ข้อมูลใบแจ้งหนี้</h4>
						<ul>
							<li>ชนิดใบแจ้งหนี้：{{ orderDetail.invoice_type == 1 ? 'ใบแจ้งหนี้กระดาษ' : 'การออกใบแจ้งหนี้ทางอิเล็กทรอนิกส์' }}</li>
							<li>ชนิดส่วนหัวของใบแจ้งหนี้：{{ orderDetail.invoice_title_type ==1 ? 'บุคคล' : 'กิจการ' }}</li>
							<li>ส่วนหัวของใบแจ้งหนี้：{{ orderDetail.invoice_title }}</li>
							<li>เนื้อหาใบแจ้งหนี้：{{ orderDetail.invoice_content }}</li>
							<li v-if="orderDetail.invoice_type == 1">ที่อยู่ทางไปรษณีย์ของใบแจ้งหนี้：{{ orderDetail.invoice_full_address }}</li>
							<li v-else>กล่องจดหมายรับใบแจ้งหนี้：{{ orderDetail.invoice_email }}</li>
						</ul>
					</div>

					<nav>
						<li :class="{ 'no-operation': !orderDetail.is_enable_refund }">ข้อมูลผลิตภัณฑ์</li>
						<li>ราคาต่อหน่วย</li>
						<li>ปริมาณ</li>
						<li>มูลค่ารวม</li>
						<li v-if="orderDetail.is_enable_refund">จัดการ</li>
					</nav>

					<!-- รายการโฆษณา·สินค้า -->
					<div class="list">
						<ul class="item" v-for="(goodsItem, goodsIndex) in orderDetail.order_goods" :key="goodsIndex">
							<li :class="{ 'no-operation': !orderDetail.is_enable_refund }">
								<div class="img-wrap" @click="$router.pushToTab('/sku-' + goodsItem.sku_id)">
									<img :src="$img(goodsItem.sku_image, { size: 'mid' })" @error="imageError(goodsIndex)" />
								</div>
								<div class="info-wrap">
									<h5 @click="$router.pushToTab('/sku-' + goodsItem.sku_id)">{{ goodsItem.sku_name }}</h5>
									<!-- <span>สเปค：สเปค值</span> -->
								</div>
							</li>
							<li>
								<span>฿{{ goodsItem.price }}</span>
							</li>
							<li>
								<span>{{ goodsItem.num }}</span>
							</li>
							<li>
								<span>฿{{ (goodsItem.price * goodsItem.num).toFixed(2) }}</span>
							</li>
							<li v-if="orderDetail.is_enable_refund">
								<el-button
									type="primary"
									plain
									size="mini"
									v-if="goodsItem.refund_status == 0 || goodsItem.refund_status == -1"
									@click="$router.push({ path: '/member/refund', query: { order_goods_id: goodsItem.order_goods_id } })"
								>
									คืน
								</el-button>
								<el-button
									type="primary"
									plain
									size="mini"
									v-else
									@click="$router.push({ path: '/member/refund_detail', query: { order_goods_id: goodsItem.order_goods_id } })"
								>
									ตรวจสอบการคืนเงิน
								</el-button>
							</li>
						</ul>
					</div>

					<!-- ยอดรวมคําสั่งซื้อ -->
					<ul class="total">
						<li>
							<label>จํานวนสินค้า：</label>
							<span>฿{{ orderDetail.goods_money }}</span>
						</li>
						<li v-if="orderDetail.member_card_money > 0">
							<label>สมาชิกภาพ：</label>
							<span>฿{{ orderDetail.member_card_money }}</span>
						</li>
						<li v-if="orderDetail.invoice_money > 0">
							<label>ภาษี：</label>
							<span>฿{{ orderDetail.invoice_money }}</span>
						</li>
						<li v-if="orderDetail.invoice_delivery_money > 0">
							<label>ไปรษณีย์ใบแจ้งหนี้：</label>
							<span>฿{{ orderDetail.invoice_delivery_money }}</span>
						</li>
						<li v-if="orderDetail.adjust_money != 0">
							<label>การปรับคําสั่งซื้อขาย：</label>
							<span>
								<template v-if="orderDetail.adjust_money < 0">
									-
								</template>
								<template v-else>
									+
								</template>
								฿{{ orderDetail.adjust_money | abs }}
							</span>
						</li>
						<li v-if="orderDetail.promotion_money > 0">
							<label>สิทธิพิเศษ：</label>
							<span>฿{{ orderDetail.promotion_money }}</span>
						</li>
						<li v-if="orderDetail.coupon_money > 0">
							<label>คูปอง：</label>
							<span>฿{{ orderDetail.coupon_money }}</span>
						</li>
						<li v-if="orderDetail.point_money > 0">
							<label>เครดิตคะแนน：</label>
							<span>฿{{ orderDetail.point_money }}</span>
						</li>
						<li v-if="orderDetail.balance_money > 0">
							<label>ใช้ยอดเงินคงเหลือ：</label>
							<span>฿{{ orderDetail.balance_money }}</span>
						</li>
						<li class="pay-money">
							<label>การชําระเงินจริง：</label>
							<span>฿{{ orderDetail.pay_money }}</span>
						</li>
					</ul>
				</template>
			</div>
		</el-card>
	</div>
</template>

<script>
import { apiOrderDetail } from '@/api/order/order';
import orderMethod from '@/utils/orderMethod';
import { mapGetters } from 'vuex';
import CountDown from "vue2-countdown"
export default {
	name: 'order_detail_virtual',
	components: {
		CountDown
	},
	mixins: [orderMethod],
	data: () => {
		return {
			orderId: 0,
			orderDetail:null,
			loading: true,
			yes: true
		};
	},
	created() {
		this.orderId = this.$route.query.order_id;
		this.getOrderDetail();
	},
	mounted() {
		let self = this;
		setTimeout(function() {
			self.yes = false
		}, 300)
	},
	computed: {
		...mapGetters(['token', 'defaultGoodsImage'])
	},
	methods: {
		getOrderDetail() {
			apiOrderDetail({
				order_id: this.orderId
			})
				.then(res => {
					if (res.code >= 0) {
						let date = (Date.parse(new Date()))/1000;
						res.data.currentTime = date
						res.data.startTime = date
						res.data.endTime = res.data.create_time + res.data.auto_close
						this.orderDetail = res.data;
						if (this.orderDetail.delivery_store_info != '') this.orderDetail.delivery_store_info = JSON.parse(this.orderDetail.delivery_store_info);
						this.loading = false;
					} else {
						this.$notify({
							message: 'ไม่ได้รับข้อมูลการสั่งซื้อ',
							title: 'คำเตือน',type: 'warning',
							duration: 2000,
							onClose: () => {
								this.$router.push({ path: '/member/order_list' });
							}
						});
					}
				})
				.catch(err => {
					this.loading = false;
					this.$message.error({
						message: err.message,
						duration: 2000,
						onClose: () => {
							this.$router.push({ path: '/member/order_list' });
						}
					});
				});
		},
		operation(action) {
			switch (action) {
				case 'orderPay': // จ่าย
					this.orderPay(this.orderDetail);
					break;
				case 'orderClose': //ปิดเครื่อง
					this.orderClose(this.orderDetail.order_id, () => {
						this.getOrderDetail();
					});
					break;
				case 'memberOrderEvaluation': //โพสต์รีวิว
					this.$router.pushToTab({ path: '/evaluate', query: { order_id: this.orderDetail.order_id } });
					break;
			}
		},
		imageError(index) {
			this.orderDetail.order_goods[index].sku_image = this.defaultGoodsImage;
		}
	},
	filters: {
		abs(value) {
			return Math.abs(parseFloat(value)).toFixed(2);
		}
	}
};
</script>
<style lang="scss" scoped>
.box {
	width: 100%;
	position: relative;
}

.null-page {
	width: 100%;
	height: 730px;
	background-color: #FFFFFF;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9;
}

.order-detail {
	.order-status {
		background-color: #fff;
		margin-bottom: 20px;
		h4 {
			margin: 10px 0 20px;
			border-bottom: 1px solid #eeeeee;
			padding-bottom: 10px;
			
			.edit-time {
				position:absolute;
				left:160px;
				top:2px;
				display: flex;
				align-items: center;
				font-size: 10px;
			}
		}
		.go-pay {
			p {
				display: inline-block;
				vertical-align: middle;
				span {
					font-weight: bold;
					color: $base-color;
					font-size: 18px;
				}
			}
		}
		.operation {
			margin-top: 10px;
		}
	}
	.order-info {
		background-color: #fff;
		margin-bottom: 10px;
		h4 {
			margin: 10px 0 20px;
			border-bottom: 1px solid #eeeeee;
			padding-bottom: 10px;
		}
		ul {
			display: flex;
			flex-wrap: wrap;
			li {
				flex: 0 0 33.3333%;
				margin-bottom: 10px;
				&:last-child {
					flex: initial;
				}
			}
		}
	}

	.verify-code-wrap {
		background-color: #fff;
		margin-bottom: 10px;
		h4 {
			margin: 10px 0 20px;
			border-bottom: 1px solid #eeeeee;
			padding-bottom: 10px;
		}
		ul {
			display: flex;
			flex-wrap: wrap;
			li {
				flex: 0 0 33.3333%;
				margin-bottom: 10px;
			}
		}
		img {
			width: 100px;
		}
		
		.virtual-code {
			text-align: center;
			
			.tips {
				color: #999;
				font-size: 12px;
				margin-top: 5px;
			}
			
		}
	}
	nav {
		overflow: hidden;
		padding: 10px 0;
		background: #fff;
		border-bottom: 1px solid #eeeeee;
		li {
			float: left;
			&:nth-child(1) {
				width: 50%;
				&.no-operation {
					width: 60%;
				}
			}
			&:nth-child(2) {
				width: 15%;
			}
			&:nth-child(3) {
				width: 10%;
			}
			&:nth-child(4) {
				width: 15%;
			}
			&:nth-child(5) {
				width: 10%;
			}
		}
	}
	.list {
		border-bottom: 1px solid #eeeeee;
		.item {
			background-color: #fff;
			padding: 10px 0;
			overflow: hidden;
			li {
				float: left;
				line-height: 60px;
				&:nth-child(1) {
					width: 50%;
					line-height: inherit;
					&.no-operation {
						width: 60%;
					}
					.img-wrap {
						width: 60px;
						height: 60px;
						float: left;
						margin-right: 10px;
						cursor: pointer;
					}
					.info-wrap {
						margin-left: 70px;
						h5 {
							font-weight: normal;
							font-size: $ns-font-size-base;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2;
							overflow: hidden;
							margin-right: 10px;
							display: inline-block;
							cursor: pointer;
							&:hover {
								color: $base-color;
							}
						}
						span {
							font-size: $ns-font-size-sm;
							color: #9a9a9a;
						}
					}
				}
				&:nth-child(2) {
					width: 15%;
				}
				&:nth-child(3) {
					width: 10%;
				}
				&:nth-child(4) {
					width: 15%;
				}
				&:nth-child(5) {
					width: 10%;
				}
			}
		}
	}
	// ทั้งหมด
	.total {
		padding: 20px;
		background-color: #fff;
		text-align: right;
		li {
			span {
				width: 150px;
				display: inline-block;
			}
			&.pay-money {
				font-weight: bold;
				span {
					color: $base-color;
					font-size: 16px;
					vertical-align: middle;
				}
			}
		}
	}
}
.take-delivery-info {
			background-color: #fff;
			margin-bottom: 10px;

			h4 {
				margin: 10px 0 20px;
				border-bottom: 1px solid #eeeeee;
				padding-bottom: 10px;
			}

			ul {
				display: flex;
				flex-wrap: wrap;

				li {
					flex: 0 0 33.3333%;
					margin-bottom: 10px;

					&:last-child {
						flex: initial;
					}
				}
			}
		}
</style>
